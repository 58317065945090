<script lang="ts" setup>
import type { BaseImageFragment } from "@/types/graphql";
// type Image = Omit<BaseImageFragment, "__typename">;
// type Image = {
//   name: string;
//   url: string;
//   previewUrl?: string | null;
//   provider: string;
//   provider_metadata?: any | null;
//   alternativeText?: string | null;
//   caption?: string | null;
//   width?: number | null;
//   height?: number | null;
//   formats?: any | null;
//   hash: string;
//   ext?: string | null;
//   mime: string;
//   size: number;
// };

// TODO: use imported image type
// Responsive image component
const props = defineProps<{
  name: string;
  url: string;
  previewUrl?: string | null;
  provider: string;
  provider_metadata?: any | null;
  alternativeText?: string | null;
  caption?: string | null;
  width?: number | null;
  height?: number | null;
  formats?: any | null;
  hash: string;
  ext?: string | null;
  mime: string;
  size: number;
  sizes?: string | null;
  eagerLoading?: boolean | null;
}>();
const config = useRuntimeConfig();
const imagePoviderUrl = config.public.apiBase;
const responsiveImages = computed(() => {
  const images = props.formats;
  if (!images) return {};
  const keys = Object.keys(images);
  const values = Object.values(images);
  const srcset = values.map(
    (value) => `${imagePoviderUrl}${value.url} ${value.width}w`
  );
  return {
    srcset: srcset.join(", "),
    sizes: props.sizes ?? "100vw",
  };
});
</script>
<template>
  <img
    :src="`${imagePoviderUrl}${props.url}`"
    :alt="props.alternativeText"
    :sizes="responsiveImages.sizes"
    :srcset="responsiveImages.srcset"
    :loading="props.eagerLoading ? 'eager' : 'lazy'"
  />
</template>
